import * as React from "react";
import styled from "styled-components";

const RectangleDivRoot = styled.div`
  width: 100%;
  position: relative;
  height: 75.063rem;
`;

const Rectangle = () => {
  return <RectangleDivRoot />;
};

export default Rectangle;
